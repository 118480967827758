import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { InputCheckbox } from "../../../components";
import { CreateAlert, onError } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";
import { withConfigWraper } from "../wraper";

type Props = {};

const RolePermission = withConfigWraper((props: Props) => {
  const rolePermissionConfig = useSelector((state) => state.rolePermissionConfig);
  const user = useSelector((state) => state.user);

  const history = useHistory();

  const checkConfig = (id: number, status: boolean) => {
    return ConfigService.updateRolePermissionServer(id, status)
      .then(async () => {
        CreateAlert({ type: "success", message: "Set config maintenance successful." });
        await ConfigService.getServerConfigRolePermission(store);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const renderItem = (data: any) => {
    return data
      ?.sort((a: any, b: any) => {
        return a.description.localeCompare(b.description);
      })
      ?.map((item: any, index: number) => {
        return (
          <div key={index} className="col-sm-6">
            <InputCheckbox
              defaultValue={item?.isActive}
              type={"checkbox"}
              label={item?.description}
              value={item?.isActive}
              onChange={(e) => checkConfig(item?.userPermissionId, e)}
              name=""
              onTouched={() => true}
            />
          </div>
        );
      });
  };

  const renderRole = () => {
    return Object.entries(rolePermissionConfig).map((role: any) => {
      if (role[0] === "ADMIN" || role[0] === "AGENT")
        if (user.userRole === "SUPER_ADMIN")
          return (
            <div className="col-sm-3 mr80">
              <h1>{role[0]}</h1>
              <div className="row role__permission--item">{renderItem(role[1])}</div>
            </div>
          );
        else return null;

      return (
        <div className="col-sm-3 mr80">
          <h1>{role[0]}</h1>
          <div className="row role__permission--item">{renderItem(role[1])}</div>
        </div>
      );
    });
  };

  if (!(user.userRole === "SUPER_ADMIN" || user.userRole === "ADMIN"))
    history.push(Routes.changePassword.path);

  return <div className="row mb20 role__permission">{renderRole()}</div>;
});

export default RolePermission;
